<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item">
                <van-radio-group v-model="status" direction="horizontal">
                  <van-radio name="1">正确</van-radio>
                  <van-radio name="-1">有异议</van-radio>
                </van-radio-group>
            </div>
                
            <div class="list-item" v-show="status==-1">
                <van-field
                  class="textarea"
                  v-model="confirm_msg"
                  rows="3"
                  autosize
                  type="textarea"
                  placeholder="异议内容"
                />
            </div>
        </div>
        
        <van-divider />
        <van-button 
            type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认          </van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    // import { toast } from '@/utils/common.js';
    export default {
        data() {
            return{
                show: false,
                confirm_msg: '',
                status: 0,
            }
        },
        methods:{
            onConfirm(){
                if(this.status == 0){
                    return Notify({ type: 'warning', message: '请选择选项' });
                }
                var data = {
                    status: this.status,
                    confirm_msg: this.confirm_msg,
                };
                console.log(data);
                if(data.status == -1){
                    if(!data.confirm_msg){
                        return Notify({ type: 'warning', message: '异议内容不能为空' });
                    }
                }
                // 提交
                this.$parent.onConFirm(data);
                this.show = false;
            },
            
            close(){
                this.confirm_msg = '';
                this.status = 0;
            },
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 80px;
        position: relative;
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
</style>